import { useUserStore } from '~~/stores/user'
export default defineNuxtRouteMiddleware((to, from) => {
    const userStore = useUserStore()
   
    if (process.client) {   
        userStore.getUser()
        if (!userStore.id) {
            return navigateTo('/')
        }
    }
})